import ShareModal from '@/components/global/share-modal';
import { GetIndexSymbolPercentGraphEmbedPath, GetIndexSymbolPublicPath } from '@/helpers/paths';
import { THEMATIC_TWITTER_HANDLE } from '@/lib/constants';

type Props = {
    isOpen: boolean;
    closeFn: () => void;
    indexSymbol: string;
};

const IndexShareModal = ({ closeFn, isOpen, indexSymbol }: Props) => {
    const link = GetIndexSymbolPublicPath(indexSymbol, { includeBaseUrl: true });
    const embed = GetIndexSymbolPercentGraphEmbedPath(indexSymbol, { includeBaseUrl: true });

    return (
        <ShareModal
            twitterShareString={`Check out this index from ${THEMATIC_TWITTER_HANDLE}`}
            gtagCategory="Index - Share Modal"
            {...{ closeFn, embed, isOpen, link }}
        />
    );
};
export default IndexShareModal;
