import { EVALUTION_REQUEST_ID_QUERY_PARAM } from '@/lib/constants';
import { InstrumentType } from '@/types/instrument';
import { Nullable } from '@/types/nullable';
import { InstrumentUrlParams } from '@/types/page';

export interface DeepDiveUrlParams {
    instrumentId: string;
    requestId?: string;
    resultTheme?: string;
    themeName?: string;
    useNewUrl?: boolean;
}

export type GetCompanyPageUrlsType = InstrumentUrlParams & {
    evaluationPage?: boolean;
    expandedModulePagePath?: Nullable<string>;
    analystRequestId?: string;
    requestId?: string;
    resultTheme?: string;
    themeName?: string;
};

export const getDeepDiveUrl = ({
    instrumentId,
    requestId,
    resultTheme,
    themeName,
    useNewUrl = false,
}: DeepDiveUrlParams) => {
    const baseUrl = useNewUrl ? '/analyst/deep-dive' : '/analyst/company';
    const companyUrl = `${baseUrl}/${instrumentId}`;
    let fullUrl = companyUrl;

    if (resultTheme) {
        fullUrl = `${fullUrl}?q=${encodeURIComponent(resultTheme)}`;
    }

    if (requestId) {
        fullUrl = resultTheme ? `${fullUrl}&r=${requestId}` : `${fullUrl}?r=${requestId}`;
    }

    if (themeName) {
        fullUrl =
            resultTheme || requestId
                ? `${fullUrl}&n=${encodeURIComponent(themeName)}`
                : `${fullUrl}?n=${encodeURIComponent(themeName)}`;
    }
    return fullUrl;
};

const symbolSpecialChars = /[/]+/g;
export const cleanTickerExchangeSymbols = (symbol: string) => symbol.replace(symbolSpecialChars, '.');
export const getCompanyPageUrls = ({
    ticker,
    exchange,
    evaluationPage = false,
    expandedModulePagePath,
    analystRequestId,
    requestId,
    resultTheme,
    themeName,
}: GetCompanyPageUrlsType) => {
    let baseUrl = `/stock/${cleanTickerExchangeSymbols(ticker)}/${cleanTickerExchangeSymbols(exchange)}`;
    let queryString = '';

    if (evaluationPage) {
        baseUrl = `${baseUrl}/evaluation`;
    }

    if (expandedModulePagePath) {
        return `${baseUrl}/details/${expandedModulePagePath}`;
    }

    if (resultTheme) {
        queryString = `${queryString}&q=${encodeURIComponent(resultTheme)}`;
    }

    if (themeName) {
        queryString = `${queryString}&n=${encodeURIComponent(themeName)}`;
    }

    if (analystRequestId) {
        queryString = `${queryString}&r=${encodeURIComponent(analystRequestId)}`;
    }

    if (requestId) {
        queryString = `${queryString}&${EVALUTION_REQUEST_ID_QUERY_PARAM}=${requestId}`;
    }

    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};

export const getTopFundamentals = (instrument?: InstrumentType) => {
    if (!instrument) return [];

    const businessFundamentals = instrument.businessFundamentals.filter(({ name }) => name === 'price_earnings_ratio');
    const tradingFundamentals = instrument.tradingFundamentals.filter(
        ({ name }) => name === 'market_capitalization_usd'
    );

    return [...businessFundamentals, ...tradingFundamentals];
};
